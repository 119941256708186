.overlayDelUser{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
}
.containerDel{
    width: 370px;
    max-width: 95%;
    background-color: white;
    margin: auto;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
}
.containerDel p{
    margin: 5px auto;
    font-weight: bold;
}
.containerDel .contBtns{
    width: 100%;
    margin: 10px 0 5px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}
.containerDel .contBtns button{
    width: 49%;
    height: 27px;
    border: none;
    outline: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: .3s;
    color: white;
}
.containerDel .contBtns .btnSim{
    background-color: green;
}
.containerDel .contBtns .btnNao{
    background-color: red;
}
.containerDel .contBtns button:hover{
    scale: 1.04;
}