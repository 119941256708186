.contentContainer{
    width: 100%;
    height: 100dvh;
    background-color: #263B4B;
    display: flex;
}
.containerImg{
    width: 50%;
    height: 100%;
    margin-left: auto;
    display: flex;
}
.containerImg img{
    width: 100%;
    object-fit: cover;
}