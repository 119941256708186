.loadScreen{
    width: 100%;
    height: 100%;
    background-color: #263B4B;
    display: flex;
    flex-direction: column;
}
.loadIcon{
    color: white;
    margin: auto;
    animation: rotate 2s linear infinite;
}
@keyframes rotate_icon{
    
    0%{
        rotate: 0deg;
    }
    100%{
        rotate: 360deg;
    }

}
#loadingLogo{
    width: 150px;
    height: auto;
    margin: auto;
    animation: breathing 2s linear infinite;
}
@keyframes breathing{
    0%, 100%{
        scale: 1;
        opacity: 0.6;
    }
    50%{
        scale: 1.1;
        opacity: 1;
    }
}