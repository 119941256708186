.overlayObrasLib{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
}
.show{
    display: flex;
}
.hide{
    display: none;
}
.contObrasLib{
    width: 550px;
    max-width: 95%;
    max-height: 80%;
    background-color: white;
    margin: auto;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.contObrasLib p{
    margin: 10px auto 0 auto;
    font-weight: bold;
}
.squareObrasLib{
    width: 100%;
    max-height: 85%;
    margin: 15px auto 0 auto;
    border-radius: 5px;
    overflow: auto;
}
.contObrasLib button{
    width: 100%;
    box-sizing: border-box;
    padding: 7px;
    margin-top: 10px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: green;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: .3s;
}
.contObrasLib button:hover{
    scale: 1.02;
}
.colunaObraName{
    text-align: left;
    padding-left: 25px;
}
.deleteObraBtn{
    width: 20px;
    height: 20px;
    color: red;
    margin: auto;
}
.deleteObraBtn:hover{
    scale: 1.03;
}