.overlayAddObra{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
}
.overlayAddObra .squareNewObra{
    width: 550px;
    background-color: white;
    margin: auto;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
}
.overlayAddObra .squareNewObra p{
    margin: 10px auto 5px auto;
    font-weight: bold;
}
.overlayAddObra .squareNewObra form{
    width: 100%;
    margin-bottom: 10px;
}
.squareNewObra form select{
    width: 100%;
    height: 30px;
    border-radius: 5px;
    outline: none;
    box-sizing: border-box;
    padding-left: 5px;
    margin-bottom: 10px;
}
.squareNewObra form button{
    width: 100%;
    height: 30px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: green;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: .3s;
}
.squareNewObra form button:hover{
    scale: 1.03;
}