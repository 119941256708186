.square{
    flex: 1;
    margin: 10px;
    background-color: white;
    border-radius: 5px;
    overflow: auto;
}
table{
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
thead{
    color: black;
    background-color: #F3F3F3;
    position: sticky;
    top: 0;
    box-shadow: 0px 8px 6px -6px rgba(0, 0, 0, 0.3);
}
tbody{
    color: black;
}
tbody tr:nth-child(even){
    background-color: rgb(222, 222, 222);
}
tbody tr:nth-child(odd){
    background-color: rgb(233, 233, 233);
}
table{
    border-collapse: collapse;
}
th, td{
    padding-left: 13px;
    padding-right: 13px;
    text-align: center;
    white-space: nowrap;
}
tbody tr{
    height: 25px;
}
thead tr{
    height: 25px;
}
tbody tr:hover{
    background-color: rgb(0, 255, 0);
    color: black;
    cursor: pointer;
    transition: .5s;
}
.square::-webkit-scrollbar {
    width: 13px;
    height: 13px;
    background-color: transparent;
}  
.square::-webkit-scrollbar-thumb {
    background-color: rgb(182, 182, 182);
    border-radius: 7px;
    border: 3px solid rgb(243, 243, 243);
}
.square::-webkit-scrollbar-corner {
    background-color: white;
    border-radius: 100%;
}