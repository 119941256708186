.loadContainer{
    margin: auto;
}
.loadContainer svg{
    animation: spinner .8s linear infinite;
}
@keyframes spinner {
    0%{
        rotate: 0deg;
    }
    100%{
        rotate: 360deg;
    }
}
.container{
    width: 600px;
    height: 95%;
    margin: auto;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.header{
    width: 100%;
    height: 50px;
    background-color: #263B4B;
    display: flex;
    align-items: center;
}
.closeContainer{
    width: 35px;
    height: 35px;
    margin: 0 10px 0 auto;
}
.iconClose{
    color: red;
    cursor: pointer;
    transition: .3s;
}
.iconClose:hover{
    scale: 1.18;
}
.body{
    flex: 1;
    background-color: #242D34;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    overflow: auto;
}
.repInfo{
    width: 100%;
    background-color:white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px;
    color: black;
}
.repInfo h3, .quartosInfo h3{
    text-align: center;
    margin: 0;
    color: black;
}
.repInfo form{
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    gap: 10px;
    box-sizing: border-box;
}
.formContainer{
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.repInfo button{
    width: 100%;
    height: 35px;
    border-radius: 5px;
    border: none;
    background-color: green;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: .3s;
}
.repInfo button:hover{
    background-color: darkgreen;
    scale: 1.02;
}
.quartosInfo{
    width: 100%;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px;
    justify-content: center;
}
.addNewQuartoBtn{
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    margin: 15px 0 0 0;
}
.addNewQuartoBtn button{
    background-color: green;
}

.quartosInfo button{
    width: 100%;
    height: 35px;
    border: none;
    border-radius: 5px;
    transition: .3s;
    cursor: pointer;
    color: white;
    font-weight: bold;
}
.quartosInfo button:hover{
    scale: 1.02;
}
.newQuartoForm{
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 10px;
    margin-top: 15px;
    color: black;
}
.btnsNewQuarto{
    width: 100%;
    height: 35px;
    display: flex;
    gap: 10px;
    margin-top: 15px;
}
.btnsNewQuarto button{
    width: calc( 50% - 5px );
}
.btnConfirm{
    background-color: green;
}
.btnCancel{
    background-color: red;
}
.btnCancel:hover{
    background-color: darkred;
}
.containerRooms{
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 10px;
    margin: 10px 0 0 0;
    gap: 10px;
}
.repRoom{
    color: black;
    background-color: #DEDEDE;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px 0;
}
.repRoom h4{
    margin: 0;
    text-align: center;
}
.tableQuarto{
    width: calc( 100% - 10px );
    margin: 10px auto 0 auto;
    border-radius: 5px;
    overflow: hidden;
}