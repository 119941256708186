.checklistSquare{
    box-sizing: border-box;
    padding: 7px 10px;
    margin: 0;
    display: flex;
    min-width: 220px;
    font-weight: bold;
    color: black;
}
.checklistSquare label{
    display: flex;
    align-items: center;
}
.checklistSquare input[type="checkbox"]{
    margin-right: 5px;
    width: 15px;
    height: 15px;
    vertical-align: middle;
}