.container{
    width: calc( 100% - 10px );
    display: flex;
    box-sizing: border-box;
    margin: 0 5px;
}
.titulo{
    width: 30%;
    height: 40px;
    display: flex;
}
.titulo p{
    margin: auto 5px auto auto;
    font-weight: bold;
    text-align: right;
}
.valor{
    width: 70%;
    height: 40px;
    box-sizing: border-box;
    display: flex;
}
.valor input[type="text"]{
    width: 100%;
    height: 35px;
    margin: auto 0;
    padding: 0;
    box-sizing: border-box;
    padding-left: 10px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    outline: none;
}