.overlayFolga-active{
    width: 100%;
    height: 100dvh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
}
.overlayFolga-disabled{
    position: absolute;
    top: 0;
    left: 0;
    display: none;
}
.squareFolga{
    width: 500px;
    max-height: 85%;
    background-color: white;
    margin: auto;
    border-radius: 5px;
    overflow: auto;
    display: flex;
    flex-direction: column;
}
#formNewFolga{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
#btnSave{
    width: 95%;
    margin: 20px auto 25px auto;
    height: 30px;
    border: none;
    border-radius: 5px;
    background-color: green;
    color: white;
    font-weight: bold;
    transition: .3s;
    cursor: pointer;
}
#btnSave:hover{
    scale: 1.02;
}
#icon_no_data{
    width: 50px;
    height: 50px;
    margin: 20px auto 5px auto;
    color: red;
}
#p_no_data{
    margin: 10px auto 5px auto;
    font-size: 15px;
    font-weight: bold;
    color: red;
}
#p_preecha_data{
    margin: 0 auto 20px auto;
    font-size: 15px;
    font-weight: bold;
    color: red;
}