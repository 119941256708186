.formDiv{
    width: 100%;
    overflow: auto;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 0 20px 15px 20px;
    margin: 0 0 10px 0;
}
.formDiv::-webkit-scrollbar{
    background-color: white;
    width: 12px;
}
.formDiv::-webkit-scrollbar-thumb{
    background-color: #263B4B;
    border: 2px solid white;
    border-radius: 15px;
}



.formDiv input[ type="submit" ]{
    width: 100%;
    height: 35px;
    margin: 25px 0 0 0;
    background-color: green;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: .3s;
}
.formDiv input[ type="submit" ]:hover{
    scale: 1.03;
}