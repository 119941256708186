.container{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.openBtn{
    width: 90%;
    height: 40px;
    display: flex;
    margin: 0 auto;
    border-radius: 5px;
}
.openBtn p{
    margin: auto 0 auto 15px;
    font-weight: bold;
    font-size: 16px;
}
.containerArrowUp{
    width: 30px;
    height: 30px;
    margin: auto 10px auto auto;
    rotate: -180deg;
    transition: .5s;
}
.containerArrowDown{
    width: 30px;
    height: 30px;
    margin: auto 10px auto auto;
    transition: .5s;
}
.iconArrow{
    width: 100%;
    height: 100%;
}