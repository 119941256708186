.RecisoesBody{
    width: 100%;
    height: calc( 100% - 70px );
    background-color: #242D34;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.RecisoesSquare{
    flex: 1;
    background-color: white;
    border-radius: 5px;
    overflow: auto;
}
.ContainerInfo{
    width: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
}
.ContainerFilters{
    width: calc( 80% - 5px );
    height: 100%;
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat( 4, 1fr );
    gap: 10px;
}
.ContainerStats{
    width: calc( 20% - 5px );
    height: 100%;
    box-sizing: border-box;
    background-color: white;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    flex-direction: column;
}
.StatsSquare{
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.StatsSquare p{
    margin: 0;
    font-weight: bold;
    text-align: center;
}
.ContainerFilter{
    flex: 1;
    height: 38px;
    display: flex;
}
.ContainerFilter input[type="text"]{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid rgba( 0, 0, 0, 0.3 );
    border-radius: 5px;
    outline: none;
    padding-left: 10px;
}
.customSelect{
    box-sizing: border-box;
    padding: 0;
    width: 100%;
    height: 100%;
}
.errorCol{
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
}
.checkCol{
    display: flex;
    align-items: center;
    justify-content: center;
    color: green;
}
.vencendo{
    background-color: yellow;
}