.container{
    width: 600px;
    max-width: 95%;
    max-height: 85%;
    margin: auto;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.container ::-webkit-scrollbar{
    width: 12px;
    height: 12px;
}
.container ::-webkit-scrollbar-thumb{
    background-color: rgb(163, 163, 163);
    border-radius: 10px;
    border: 3px solid rgb(223, 223, 223);
}
.container ::-webkit-scrollbar-track{
    background-color: rgb(223, 223, 223)
}
.header {
    background-color: #263B4B;
    height: 40px;
    width: 100%;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    position: relative; /* Adicionado */
}

.tituloForm {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    color: white;
    font-weight: bold;
    font-size: 14px;
}

.closeDiv {
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-left: auto;
    margin-right: 5px;
}
.body{
    width: 100%;
    height: calc( 100% - 40px );
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 10px 20px 20px 20px;
    overflow: auto;
}
.loading{
    width: 100%;
    height: 150px;
    background-color: white;
    color: black;
    display: flex;
}
.loadingContainer{
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.loadingContainer p{
    margin: 0;
}
.spinner{
    width: 35px;
    height: 35px;
    animation: spin 1s linear infinite;
    
}
@keyframes spin {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
.listaAnexos{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.titleAnexos{
    margin: 15px auto 15px auto;
    color: black;
    font-weight: bold;
}
.tabelaAnexosCont{
    width: 100%;
    margin: auto;
    border-radius: 5px;
    overflow: hidden;
}
.closeIcon{
    width: 100%;
    height: 100%;
    color: red;
}