.navbar{
    width: 100%;
    height: 70px;
    background-color: #263B4B;
    display: flex;
    margin: 0;
    position: relative;
}
#Navbar_logo{
    height: 60%;
    margin: auto 0 auto 10px;
    cursor: pointer;
}
#burgerMenu{
    color: white;
    margin: auto 15px auto auto;
}
.versionText{
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );
    color: white;
    font-weight: bold;
    font-size: 14px;
    white-space: nowrap;
}
@media (max-width: 760px) {
    .versionText{
        display: none;
    }
}