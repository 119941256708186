.body{
    width: 100%;
    height: calc( 100% - 70px );
    background-color: #242D34;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.container1{
    width: 100%;
    display: flex;
    gap: 10px;
}
.filtersContainer{
    flex: 1;
    background-color: #F3F3F3;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat( 4, 1fr );
    gap: 10px;
}
.statsContainer{
    width: 250px;
    background-color: #F3F3F3;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
}
.statsLeft{
    width: 50%;
    height: 100%;
    margin: 0 auto 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
}
.statsLeft p{
    margin: 0;
    font-weight: bold;
}
.statsRight{
    width: 50%;
    height: 100%;
    margin: 0 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
}
.statsRight p{
    margin: 0;
    font-weight: bold;
}
.filterBox{
    flex: 1;
    height: 38px;
    display: flex;
}

.container2{
    width: 100%;
    flex: 1;
    background-color: #F3F3F3;
    border-radius: 5px;
    overflow: auto;
}
.customSelect{
    box-sizing: border-box;
    padding: 0;
    width: 100%;
    height: 100%;
}
.btnPdf{
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.iconPdf{
    width: 18px;
    height: 18px;
}
.iconLoad{
    width: 18px;
    height: 18px;
    animation: spin 1s linear infinite;
}
@keyframes spin {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}