.container{
    width: 550px;
    max-height: 85%;
    margin: auto;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.header{
    width: 100%;
    height: 40px;
    background-color: #263B4B;
    display: flex;
    flex-shrink: 0;
    position: relative;
}
.closeBtn{
    width: 30px;
    height: 30px;
    margin: auto 0;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
}
.closeBtn svg{
    width: 100%;
    height: 100%;
    color: red;
    cursor: pointer;
    transition: .3s;
}
.closeBtn svg:hover{
    scale: 1.15;
}
.formContainer{
    width: 100%;
    height: calc( 100% - 40px );
    box-sizing: border-box;
    padding: 7px 0 0 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: white;
}
.formContainer form{
    width: 90%;
    margin: auto;
}
.formContainer form button{
    width: 100%;
    height: 35px;
    margin: 25px 0 20px 0;
    background-color: green;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: .3s;
}
.formContainer form button:hover{
    scale: 1.02;
    background-color: darkgreen;
}
.formContainer::-webkit-scrollbar{
    width: 7px;
    height: 7px;
}
.formContainer::-webkit-scrollbar-thumb{
    background-color: #263B4B;
    border-radius: 3px;
}
.formContainer::-webkit-scrollbar-track{
    background-color: rgb(211, 211, 211);
}