.bodyFolga{
    width: 100%;
    height: calc( 100% - 70px );
    background-color: #242D34;
    display: flex;
    flex-direction: column;
}
.distanceSquare{
    margin: 10px;
    background-color: white;
    flex: 1;
    border-radius: 5px;
    overflow: auto;
}