.statsContainer{
    width: calc( 15% - 2.5px );
    background-color: #DEDEDE;
    border-radius: 5px;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.statsContainer p{
    margin: 0;
    font-weight: bold;
}