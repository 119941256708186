.formChild{
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    color: black;
}
.formChild p{
    margin: 15px auto 10px auto;
    font-weight: bold;
}
.formChild select{
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    outline: none;
    box-sizing: border-box;
    padding-left: 10px;
}
.formChild input[ type="text" ]{
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    outline: none;
    box-sizing: border-box;
    padding-left: 10px;
}
.formChild input[ type="date" ]{
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    outline: none;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
}
.formChild input[ type="datetime-local" ]{
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    outline: none;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
}
.formChild input[ type="file"]{
    width: 100%;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}
.formChild input[ type="number"]{
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    outline: none;
    box-sizing: border-box;
    padding-left: 10px;
}
.formChild input[ type="submit" ]{
    width: 100%;
    height: 35px;
    border-radius: 5px;
    border: none;
    outline: none;
    box-sizing: border-box;
    background-color: green;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: .3s;
}
.formChild input[ type="submit" ]:hover{
    scale: 1.01;
}
.formChild input[ type="submit" ]:disabled{
    background-color: #C1C1C1;
}
.formChild textarea{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 130px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}