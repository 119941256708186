.contentFolga{
    height: 100dvh;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.newKM{
    margin: auto 15px auto auto;
    color: white;
    cursor: pointer;
    transition: .3s;
}
.newKM:hover{
    scale: 1.16;
}