.colabBody {
    width: 100%;
    height: calc( 100% - 70px );
    background-color: #242D34;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding: 10px;
    gap: 10px;
}
.colabInfo {
    width: calc( 30% - 5px );
    height: 100%;
    background-color: #DEDEDE;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
}
.colabPicContainer {
    width: 100%;
    height: 120px;
    display: flex;
}
.colabPic {
    height: 95%;
    aspect-ratio: 1 / 1;
    background-color: red;
    margin: auto;
    border-radius: 50%;
}
.colabContainerInfo {
    width: 100%;
    height: calc(100% - 130px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    box-shadow: 0 0 2px 0.3px rgba(0, 0, 0, 0.5);
    border-radius: 7px;
    box-sizing: border-box;
    padding: 5px 2px;
}
.colabContainerInfo::-webkit-scrollbar{
    width: 12px;
}
.colabContainerInfo::-webkit-scrollbar-track{
    background-color: #DEDEDE;
}
.colabContainerInfo::-webkit-scrollbar-thumb{
    background-color: #9E9E9E;
    border-radius: 7px;
    border: 3px solid #DEDEDE;
}
.colabFill {
    width: calc( 70% - 5px );
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
}
.fillContainer{
    width: 100%;
    background-color: #DEDEDE;
    box-sizing: border-box;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}
.fillContainer h3{
    margin: 0;
    text-align: center;
}
.fillContainer form{
    display: flex;
    flex-direction: column;
    margin: 10px 0 0 0;
    gap: 5px;
}
.fillContainer button{
    margin: 5px 10px 0 10px;
    height: 35px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background-color: green;
    font-weight: bold;
    color: white;
    cursor: pointer;
    transition: .3s;
}
.fillContainer button:hover{
    scale: 1.01;
}
.folgasField{
    width: 100%;
    background-color: #DEDEDE;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 15px 10px;
}
.folgasField h3{
    text-align: center;
    margin: 0;
}
.folgasTableContainer{
    width: 100%;
    background-color: red;
    display: flex;
    flex-direction: column;
    overflow: auto;
    border-radius: 5px;
    margin-top: 10px;
}