.pagamentosBody{
    width: 100%;
    height: calc( 100% - 70px );
    background-color: #242D34;
    display: flex;
    flex-direction: column;
}
.pagHeaderCont{
    width: calc( 100% - 20px );
    height: 95px;
    margin: 10px;
    display: flex;
    justify-content: space-between;
}
.filterPagamentos{
    width: calc(70% - 2.5px);
    height: 100%;
    background-color: #DEDEDE;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}
.valuesContainer{
    width: calc( 30% - 2.5px );
    height: 100%;
    background-color: #DEDEDE;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}
.squarePagamentos{
    width: calc( 100% - 2 0px );
    height: calc( 100% - 125px );
    background-color: #F3F3F3;
    margin: 0 10px 10px 10px;
    border-radius: 5px;
    overflow: auto;
}
.row-filter-1, .row-filter-2{
    width: calc( 100% - 10px );
    height: calc( 50% - 7.5px );
    margin: auto;
    display: flex;
    justify-content: space-between;
}
.filter_container{
    height: 100%;
    width: calc( 33.33% - 3px );
    /* box-shadow: inset 0 0 0 1px red; */
    display: flex;
}
.date_filter{
    width: calc( 50% - 3px );
}
.date_filter input{
    width: 100%;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 0 10px;
}
.filter_container select{
    width: 100%;
    border-radius: 5px;
    border: none;
    outline: none;
    padding: 0 0 0 10px;
}