.tableDistance{
    width: 550px;
    max-height: 80%;
    background-color: white;
    border-radius: 5px;
    margin: auto;
    box-sizing: border-box;
    padding: 10px;
}
.tableDistance form{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    box-sizing: border-box;
    padding-top: 15px;
    padding-bottom: 15px;
    overflow: auto;
}
.formDiv{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.formDiv p{
    margin: 0;
    text-align: center;
    font-weight: bold;
}
.formDiv input[type="text"]{
    width: 90%;
    height: 38px;
    margin: auto;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    padding-left: 10px;
}
.formDiv button{
    width: 90%;
    height: 38px;
    margin: auto;
    background-color: green;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    margin-top: 15px;
    cursor: pointer;
    transition: .3s;
    font-weight: bold;
}
.formDiv button:hover{
    scale: 1.03;
}