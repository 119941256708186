.overlayRepList{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
}
.squareRepList{
    width: 80%;
    max-height: 80%;
    background-color: white;
    margin: auto;
    border-radius: 5px;
    overflow: auto;
}