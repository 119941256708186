.bodyContainer{
    width: 100%;
    height: calc( 100% - 70px );
    background-color: #242D34;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.header{
    width: 100%;
    display: flex;
    gap: 10px;
}
.filtersContainer{
    width: calc( 70% - 5px );
    height: 100%;
    border-radius: 5px;
    background-color: #F3F3F3;
    box-sizing: border-box;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat( 2, 1fr );
    gap: 10px;
}
.statsContainer{
    width: calc( 30% - 5px );
    height: 100%;
    border-radius: 5px;
    background-color: #F3F3F3;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
}
.statsText{
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.statsText p{
    margin: 0;
    text-align: center;
    font-weight: bold;
}
.bodyTable{
    width: 100%;
    flex: 1;
    background-color: #F3F3F3;
    border-radius: 5px;
    overflow: auto;
    position: relative;
}
.filterChild{
    flex: 1;
    height: 38px;
    display: flex;
}
.customSelect{
    box-sizing: border-box;
    padding: 0;
    width: 100%;
    height: 100%;
}
.loading{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}
.loading svg{
    color: white;
    animation: spinner 1s linear infinite;
}
@keyframes spinner {
    0%{
        rotate: 0deg;
    }
    100%{
        rotate: 360deg;
    }
}