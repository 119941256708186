.container{
    width: 350px;
    background-color: white;
    margin: auto;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 5px 10px;
}
.container p{
    margin: 10px 0;
    font-weight: bold;
    text-align: center;
    color: black;
}
.containerBtns{
    width: 100%;
    height: 30px;
    display: flex;
    gap: 10px;
    margin: 15px 0 5px 0;
}
.containerBtns button{
    width: calc( 50% - 5px );
    height: 100%;
    border-radius: 5px;
    outline: none;
    border: none;
    font-weight: bold;
    cursor: pointer;
    transition: .3s;
}
.containerBtns button:hover{
    scale: 1.03;
}
.btnConfirm{
    background-color: green;
}
.btnCancel{
    background-color: red;
}