.overlayAddBenefit{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
}
.squareAddBenefit{
    width: 550px;
    background-color: white;
    border-radius: 5px;
    margin: auto;
}
.squareAddBenefit form{
    display: flex;
    flex-direction: column;
}
.benefitField{
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 10px auto;
}
.benefitField p{
    margin: 5px auto;
    font-weight: bold;
}
.benefitField select{
    height: 40px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}
.benefitField input{
    height: 40px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}
.submitBenefit{
    width: 90%;
    height: 40px;
    margin: 20px auto 30px auto;
    border: none;
    outline: none;
    background-color: green;
    border-radius: 5px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    transition: .3s;
}
.submitBenefit:hover{
    scale: 1.02;
}
.benefitClose{
    width: 100%;
    height: 35px;
    display: flex;
    margin-top: 5px;
}
.benefitClose svg{
    width: 30px;
    height: 30px;
    color: red;
    margin: auto 10px auto auto;
    transition: .3s;
    cursor: pointer;
}
.benefitClose svg:hover{
    scale: 1.15;
}