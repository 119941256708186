.container{
    width: 100%;
    display: flex;
    gap: 10px;
}
.filtersContainer{
    width: calc( 80% - 5px );
    background-color: #F3F3F3;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat( 5, 1fr );
    gap: 10px;
}
.filterBox{
    flex: 1;
    height: 38px;
    display: flex;
}
.filterBox input[type="text"]{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 0 10px;
    outline: none;
}
.filterBox input[type="date"]{
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 10px;
}
.customSelect{
    box-sizing: border-box;
    padding: 0;
    width: 100%;
    height: 100%;
}
.statsContainer{
    width: calc( 20% - 5px );
    background-color: #F3F3F3;
    border-radius: 5px; 
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px;
}
.statsBox{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: auto;
    text-align: center;
    gap: 5px;
}
.statsBox p{
    margin: 0;
    font-weight: bold;
}