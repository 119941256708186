.container{
    width: 100%;
    flex: 1;
    background-color: #242D34;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: column;
}
.square{
    width: 100%;
    flex: 1;
    background-color: #F3F3F3;
    border-radius: 5px;
    overflow: auto;
}