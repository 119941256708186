.overlayNewPagamento{
    width: 100%;
    height: 100dvh;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
}
.hide{
    display: none;
}
.show{
    display: flex;
}
.squareNewPag{
    width: 550px;
    max-height: 85%;
    background-color: white;
    margin: auto;
    border-radius: 5px;
    overflow: auto;
}
.headerNewPag{
    width: 100%;
    height: 45px;
    display: flex;
}
.headerNewPag svg{
    margin: auto 5px auto auto;
    transition: .3s;
    cursor: pointer;
    color: red;
}
.headerNewPag svg:hover{
    scale: 1.16;
}
.bodyNewPag{
    width: 100%;
    flex: 1;
    margin: auto;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.bodyNewPag p{
    margin: 0;
}
.form_pag_field{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.form_pag_cont{
    width: 90%;
    height: 40px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}
.area-text{
    height: auto;
}
.area-text textarea{
    box-sizing: border-box;
    max-width: 100%;
    height: 110px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    outline: none;
    padding: 10px;
    font-size: 13px;
    text-align: justify;
}
.btn_save_pag{
    margin: 25px auto;
}
.form_pag_field p{
    margin: 10px auto;
    font-weight: bold;
}
.form_pag_cont select{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    padding-left: 15px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    outline: none;
}
.form_pag_cont input{
    width: calc( 100% - 15px );
    height: 100%;
    border-radius: 5px;
    padding: 0;
    border: none;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.6);
    padding-left: 15px;
}
#submit_btn{
    width: 100%;
    background-color: green;
    color: white;
    font-weight: bold;
    transition: .3s;
    border: none;
    box-shadow: none;
    cursor: pointer;
}
#submit_btn:hover{
    scale: 1.02;
}
