.overlay{
    width: 100%;
    height: 100dvh;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 99999;
    top: 0;
    left: 0;
    color: white;
}
.show{
    display: flex;
}
.hide{
    display: none;
}
.transparentBack{
    background-color: transparent;
}