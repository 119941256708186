.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}
.container p{
    margin: 5px 0;
    text-align: center;
    font-weight: bold;
}
.container input[type="text"], .container input[type="number"],  .container select{
    width: 100%;
    height: 35px;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgba(0,0,0,0.3);
    box-sizing: border-box;
    padding-left: 10px;
}