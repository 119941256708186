.usersSquare{
    overflow: auto;
    border-radius: 5px;
}
.usersSquare::-webkit-scrollbar{
    width: 12px;
    height: 12px;
}
.usersSquare::-webkit-scrollbar-track{
    background: #F3F3F3;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.usersSquare::-webkit-scrollbar-thumb{
    background-color: #bebebe;
    border-radius: 5px;
    border: 3px solid #F3F3F3;
}

.usersSquare thead tr{
    height: 35px;
    font-size: 13px;
}
.usersSquare tbody tr{
    height: 35px;
}
.linhaFunc{
    width: 100px;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
.botoes{
    height: 35px;
    box-sizing: border-box;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.botoes svg{
    height: 25px;
    width: 25px;
    color: rgba(0, 0, 0, 0.7);
    transition: .3s;
}
.delete_btn:hover{
    scale: 1.16;
    color: red;
}