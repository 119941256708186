.filterChild{
    flex: 1;
    min-width: 300px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}
.filterChild select{
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
    border-radius: 5px;
    box-sizing: border-box;
    padding-left: 8px;
}
.filterChild input[type="date"]{
    width: calc( 50% - 2.5px );
    height: 40px;
    border: none;
    outline: none;
    border-radius: 5px;
    padding-left: 10px;
    box-sizing: border-box;
}
.filterChild input[type="text"]{
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
    border-radius: 5px;
    padding-left: 10px;
    box-sizing: border-box;
}