.bodyContainer{
    width: 100%;
    height: calc( 100dvh - 70px );
    background-color: #242D34;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.squareTable{
    width: 100%;
    flex: 1;
    background-color: #DEDEDE;
    border-radius: 5px;
    overflow: auto;
}
.squareTable::-webkit-scrollbar {
    width: 13px;
    height: 13px;
    background-color: transparent;
}  
.squareTable::-webkit-scrollbar-thumb {
    background-color: rgb(182, 182, 182);
    border-radius: 7px;
    border: 3px solid #DEDEDE;
}
.squareTable::-webkit-scrollbar-corner {
    background-color: white;
    border-radius: 100%;
}
.headerContainer{
    display: flex;
    gap: 10px;
}
.filtersContainer{
    width: 80%;
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat( 4, 1fr );
    gap: 10px;
}
.filterChild{
    height: 38px;
    display: flex;
}
.filterChild input[type="text"]{
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: none;
    border-radius: 4px;
    box-sizing: border-box;
    padding-left: 10px;
}
.filterChild input[type="date"]{
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: none;
    border-radius: 4px;
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 10px;
}
.selectFilter{
    width: 100%;
}
.statsContainer{
    width: 20%;
    height: 100%;
    background-color: white;
    border-radius: 5px;
    display: flex;
    padding: 10px;
    box-sizing: border-box;
}
.statsBox{
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 5px;
    margin: auto;
}
.statsBox p{
    margin: 0;
    font-weight: bold;
}
.loadingOverlay{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
}
.loadingContainer{
    width: 35px;
    height: 35px;
    background-color: white;
    border-radius: 3px;
    margin: auto;
    display: flex;
    box-sizing: border-box;
    padding: 5px;
}
.loadingIcon{
    margin: auto;
    width: 100%;
    height: 100%;
    animation: spinner .8s linear infinite;
}

@keyframes spinner {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}