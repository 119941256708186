.container{
    width: 100%;
    height: calc( 100%  - 70px );
    background-color: #242D34;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px;
    gap: 10px;
}
.header{
    width: 100%;
    display: flex;
    gap: 10px;
}
.filtersContainer{
    flex: 1;
    background-color: #F3F3F3;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat( 3, 1fr );
    gap: 10px;
}
.filtersChild{
    flex: 1;
    height: 38px;
    display: flex;
}
.customSelect{
    box-sizing: border-box;
    padding: 0;
    width: 100%;
    height: 100%;
}
.statsContainer{
    width: 280px;
    height: 100%;
    background-color: #F3F3F3;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    gap: 15px;
}
.statsBox{
    margin: auto auto auto 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.statsBox p{
    margin: 0;
    font-weight: bold;
}
.updateBtnDiv{
    display: flex;
    flex: 1;
}
.updateBtnDiv button{
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    width: 100%;
    cursor: pointer;
    transition: .3s;
}
.updateBtnDiv button:hover{
    scale: 1.05;
}

.loadingDiv{
    width: 100%;
    height: 100%;
    display: flex;
}
.loadingCont{
    margin: auto;
    display: flex;
    gap: 7px;
    align-items: center;
    justify-content: center;
}
.loadingCont p{
    font-size: 13px;
    margin: 0;
}
.iconLoad{
    animation: spinner 1s linear infinite;
}
@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.body{
    width: 100%;
    flex: 1;
    background-color: #F3F3F3;
    border-radius: 5px;
    overflow: auto;
}
.body::-webkit-scrollbar{
    width: 10px;
    height: 8px;
}
.body::-webkit-scrollbar-thumb{
    background-color: #C1C1C1;
    border-radius: 5px;
    border: 2px solid #F3F3F3;
}
.tabela{
    overflow: auto;
    flex: 1;
}
.itemColumn{
    text-align: left;
}
.checkIcon{
    color: green;
    width: 18px;
    height: 18px;
}
.xIcon{
    color: red;
    width: 18px;
    height: 18px;
}