.registerContent{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
}
.logoPalmont{
    height: 55px;
    margin: auto auto 0 auto;
}
.containerRegister{
    width: 70%;
    margin: auto;
}
#formulario{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.fieldForm{
    margin: 5px 0;
    display: flex;
}
.fieldForm input{
    height: 40px;
    width: 100%;
    border: none;
    outline: none;
    border-radius: 5px;
    padding-left: 10px;
}
.nameInput, .surnameInput{
    width: 50%;
    display: flex;
}
.nameInput input{
    width: calc( 100% - 15px );
    margin-right: auto;
    color: rgba(0, 0, 0, 0.6);
    cursor: default;
}
.surnameInput input{
    width: calc( 100% - 15px );
    margin-left: auto;
    color: rgba(0, 0, 0, 0.6);
}
#formulario button{
    height: 40px;
    margin-top: 25px;
    border-radius: 5px;
    border: none;
    background-color: #0FB50B;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: .3s;
}
#formulario button:hover{
    scale: 1.03;
}