.sideMenuBtn{
    width: 85%;
    height: 40px;
    background-color: transparent;
    margin: 7px auto;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: .3s;
    display: flex;
}
.sideMenuBtn:hover{
    background-color: white;
    color: #263B4B;
    scale: 1.04;
}
.sideMenuBtn p{
    font-weight: bold;
    font-size: 16px;
    margin: auto 0 auto 7px;
}
.divIcon{
    width: 25px;
    height: 25px;
    margin: auto 7px auto 25px;
}
.divIcon svg{
    width: 100%;
    height: 100%;
}
.spinner {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}