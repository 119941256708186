.folhaPagSquare{
    width: 100%;
    flex: 1;
    margin-top: 10px;
    background-color: white;
    overflow: auto;
    border-radius: 5px;
}
.delRow{
    width: 20px;
    height: 20px;
    color: red;
}
.folhaPagSquare td{
    height: 30px;
}