.overlayListBenefits{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
}
.show{
    display: flex;
}
.hide{
    display: none;
}
.squareListBenefits{
    width: 750px;
    max-width: 95%;
    max-height: 95%;
    background-color: white;
    border-radius: 5px;
    margin: auto;
    display: flex;
    flex-direction: column;
}
.benefits_list_title{
    margin: 5px auto 15px auto;
}
.square_tabela_benefits{
    width: 95%;
    height: 250px;
    margin: 0 auto;
    background-color: #c9c9c9;
    border-radius: 5px;
    overflow: auto;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.5);
}
.btn_new_benefits{
    width: 95%;
    height: 35px;
    margin: 15px auto 15px auto;
    cursor: pointer;
    background-color: green;
    color: white;
    font-weight: bold;
    border: none;
    outline: none;
    border-radius: 5px;
    transition: .3s;
}
.btn_new_benefits:hover{
    scale: 1.02;
}
.active_benefit{
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: green;
    margin: auto;
}
.inactive_benefit{
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: red;
    margin: auto;
}
.trash_cel svg{
    color: rgb(165, 0, 0);
}
.trash_cel svg:hover{
    scale: 1.15;
}
.benefitsListClose{
    width: 100%;
    height: 35px;
    display: flex;
    color: red;
}
.benefitsListClose svg{
    width: 30px;
    height: 30px;
    margin: auto 5px auto auto;
    transition: .3s;
    cursor: pointer;
}
.benefitsListClose svg:hover{
    scale: 1.15;
}