.bodyRep{
    width: 100%;
    height: calc( 100% - 70px );
    background-color: #242D34;
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    box-sizing: border-box;
}
.repHeader{
    width: 100%;
    display: flex;
    gap: 10px;
}
.repFilters{
    width: calc( 80% - 5px );
    background-color: #F3F3F3;
    border-radius: 5px;
    display: grid;
    grid-template-columns: repeat( 2, 1fr );
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
}
.repStats{
    width: calc( 20% - 5px );
    background-color: #F3F3F3;
    border-radius: 5px;
}
.repSquare{
    flex: 1;
    width: 100%;
    background-color: #F3F3F3;
    border-radius: 5px;
    overflow: auto;
}
.repFilterContainer{
    height: 38px;
    display: flex;
}
.repFilterContainer input[type="text"]{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    box-sizing: border-box;
    padding-left: 12px;
    outline: none;
}
.customSelect{
    width: 100%;
}
.viewAlojados svg{
    cursor: pointer;
    transition: .3s;
}
.viewAlojados svg:hover{
    scale: 1.2;
}
.editAlojamentos svg{
    color: blue;
    cursor: pointer;
}
 .editAlojamentos svg:hover{
    scale: 1.2;
}
.deleteAlojamento{
    color: red;
    cursor: pointer;
}
.deleteAlojamento:hover{
    scale: 1.2;
}

table tbody .linhaTabela{
    cursor: auto;
}