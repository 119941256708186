.container{
    width: 550px;
    max-height: 80%;
    margin: auto;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.header{
    width: 100%;
    height: 50px;
    display: flex;
    position: relative;
    color: black;
}
.header h3{
    margin: auto;
}
.iconHeader{
    width: 30px;
    height: 30px;
    background-color: white;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}
.iconHeader svg{
    color: red;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.body{
    width: 100%;
    flex: 1;
    overflow: auto;
}
.body form{
    width: 90%;
    margin: auto;
}
.btnSubmit{
    width: 100%;
    margin: 20px 0;
    height: 40px;
    border-radius: 5px;
    border: none;
    background-color: green;
    color: white;
    cursor: pointer;
    font-weight: bold;
    transition: .3s;
}
.btnSubmit:hover{
    scale: 1.03;
    background-color: darkgreen;
}
.loading{
    margin: auto;
    background-color: white;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 5px 10px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 3px;
}
.errorPage{
    margin: auto;
    background-color: white;
    box-sizing: border-box;
    padding: 5px 10px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 3px;
    color: red;
}
.errorPage p{
    margin: 0;
}