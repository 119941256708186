.headerContent{
    width: calc( 100% - 20px );
    margin: 10px 10px 0 10px;
    display: flex;
    flex-direction: row;
}
.filtersDiv{
    width: calc( 75% - 5px );
    background-color: #DEDEDE;
    padding: 5px;
    border-radius: 5px;
    margin-right: 5px;
}
.filterRow1{
    display: flex;
    margin-bottom: 5px;
    height: 40px;
}
.filterName{
    width: 33.33%;
    margin-right: 5px;
    border-radius: 5px;
    border: none;
    padding-left: 10px;
}
.filterFunc{
    width: 33.33%;
    margin-left: 5px;
    border-radius: 5px;
    border: none;
    padding: 0 10px;
}
.filterMat{
    width: 33.33%;
    border-radius: 5px;
    border: none;
    padding: 0 10px;
}
.filterRow2{
    display: flex;
    margin-top: 5px;
    height: 40px;
}
.obraFilter{
    border-radius: 5px;
    border: none;
    padding: 0 10px;
    width: 33.33%;
    margin-right: 5px;
}
.maoFilter{
    border-radius: 5px;
    border: none;
    padding: 0 10px;
    width: 33.33%;
}
.statusFilter{
    border-radius: 5px;
    border: none;
    padding: 0 10px;
    width: 33.33%;
    margin-left: 5px;
}
.statsInfo{
    width: calc( 25% - 5px );
    background-color: #DEDEDE;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}
.statsInfo p{
    font-weight: bold;
}
.colabsGeral{
    margin: auto;
}
.colabstatus1{
    margin: auto;
}
.colabstatus2{
    margin: auto;
}
.colabsMO{
    margin: auto;
}