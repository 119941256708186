.overlay{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100dvh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9999;
}
.active{
    display: flex;
}
.disabled{
    display: none;
}
.sidebarMenu{
    width: 450px;
    height: 100%;
    background-color:#263B4B;
    margin: 0 0 0 auto;
    display: flex;
    flex-direction: column;
}
.sidebarBtn{
    display: flex;
    font-weight: bold;
    color: white;
    margin: 20px auto 0 auto;
    width: 80%;
    height: 40px;
    cursor: pointer;
    border-radius: 5px;
    border: 2px solid white;
    transition: .3s;
}
.sidebarBtn:hover{
    background-color: white;
    color: #263B4B;
}
.IconBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 10px auto auto;
}
.sidebarBtn p{
    margin: auto auto auto 0;
}
#closeSideBar{
    margin: 15px 15px 0 auto;
    color: red;
    cursor: pointer;
    transition: .3s;
}
#closeSideBar:hover{
    scale: 1.15;
}