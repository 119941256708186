.container{
    width: 550px;
    max-height: 80%;
    border-radius: 5px;
    margin: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.header{
    width: 100%;
    height: 40px;
    background-color: #263B4B;
    display: flex;
}
.closeIconBox{
    width: 30px;
    height: 30px;
    margin: auto 5px auto auto;
}
.closeIcon{
    width: 100%;
    height: 100%;
    color: red;
    cursor: pointer;
    transition: .3s;
}
.closeIcon:hover{
    scale: 1.20;
}
.bodyForm{
    width: 100%;
    background-color: white;
}
.formluario{
    width: 90%;
    margin: 10px auto 20px auto;
}