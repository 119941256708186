.container{
    width: 550px;
    max-width: 95%;
    max-height: 80%;
    margin: auto;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.header{
    width: 100%;
    height: 40px;
    background-color: #263B4B;
    display: flex;
    flex-shrink: 0;
}
.closeBox{
    width: 30px;
    height: 30px;
    margin: auto 5px auto auto;
    cursor: pointer;
}
.closeIcon{
    width: 100%;
    height: 100%;
    color: red;
    transition: .3s;
}
.closeIcon:hover{
    scale: 1.15;
}
.body{
    width: 100%;
    color: black;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: white;
}
.loadingDiv{
    margin: 40px auto;
    display: flex;
    box-sizing: border-box;
    border-radius: 5px;
}
.loadingDivTxt{
    margin: auto 2.5px auto 0;
}
.loadingDivTxt p{
    margin: 0;
    font-weight: bold;
    font-size: 13px;
}
.loadingDivIcon{
    margin: auto 0 auto 2.5px;
    display: flex;
}
.loadingDivIcon svg{
    width: 25px;
    height: 25px;
    margin: 0;
    animation: spinner 1s infinite linear;
}
@keyframes spinner{
    0%{
        transform: rotate( 0deg );
    }
    100%{
        transform: rotate( 360deg );
    }
}
.editedDiv{
    width: 95%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 15px auto;
}
.editedTitle{
    margin: 0 auto;
    font-weight: bold;
    font-size: 13px;
}
.editedValue{
    max-width: 100%;
    min-width: 100%;
    box-sizing: border-box;
    height: 100px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 10px 0 0 0;
    outline: none;
}
.editedByTxt{
    margin: 0;
}