.squareObs {
    width: 550px;
    max-width: 95%;
    max-height: 90%;
    box-sizing: border-box;
    background-color: white;
    margin: auto;
    border-radius: 5px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
}
.squareObs textarea {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    box-sizing: border-box;
    height: 28px;
    flex-shrink: 0; /* Impede o encolhimento */
    padding: 5px;
}
.obsHeader {
    width: 100%;
    flex-shrink: 0;
    position: relative;
    margin-top: 9px;
    box-sizing: border-box;
}
.obsHeader p{
    margin: 0;
    text-align: center;
    font-weight: bold;
}
.newObsArea{
    width: 100%;
    height: 80px;
}
.saveBtn{
    width: 100%;
    height: 30px;
    cursor: pointer;
    background-color: green;
    border: none;
    outline: none;
    border-radius: 5px;
    font-weight: bold;
    transition: .3s;
    color: white;
}
.saveBtn:hover{
    scale: 1.015;
}
.closeIcon{
    color: red;
    height: 25px;
    width: 25px;
    position: absolute;
    top: -9px;
    right: 0;
}