.folhaPagHeader{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    border-radius: 5px;
    justify-content: space-between;
}
.filtersFolhaPag{
    display: flex;
    flex-direction: column;
    width: calc( 77% - 2.5px );
    box-sizing: border-box;
    padding: 5px;
    background-color: #DEDEDE;
    border-radius: 5px;
}
.filtersFolhaPag .filter-row-1, .filtersFolhaPag .filter-row-2{
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}
.filtersFolhaPag .filter-row-1{
    margin-bottom: 2.5px;
}
.filtersFolhaPag .filter-row-2{
    margin-top: 2.5px;
}
.filterMatricula{
    width: 33%;
    border: none;
    outline: none;
    border-radius: 5px;
    padding-left: 15px;
    box-sizing: border-box;
}
.filtersFolhaPag .filter-row-1 select{
    width: 33%;
    border: none;
    outline: none;
    border-radius: 5px;
    padding-left: 5px;
    box-sizing: border-box;
}
.filtersFolhaPag .filter-row-2 input{
    border: none;
    border-radius: 5px;
    outline: none;
    box-sizing: border-box;
    width: calc( 50% - 2.5px );
    padding: 0 15px;
}
.statsFolhaPag{
    width: calc( 23% - 2.5px );
    border-radius: 5px;
    background-color: #DEDEDE;
    display: flex;
}
.summaryPag{
    padding: 8px 13px;
    background-color: #F0F4F8;
    margin: auto;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: .3s;
}
.summaryPag:hover{
    scale: 1.06;
}
.summaryPag p{
    margin: 0;
    font-size: 16px;
    font-weight: bold;
}