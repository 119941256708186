.homeBody{
    width: 100%;
    height: calc(100% - 70px);
    background-color: #242D34;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    overflow: hidden;
}
.homeHeader{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.filtersCont{
    width: calc( 80% - 5px );
    height: 100%;
    border-radius: 5px;
    background-color: #F3F3F3;
    box-sizing: border-box;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat( 4, 1fr );
    gap: 10px;
}
.containerFilter{
    flex: 1;
    height: 38px;
    display: flex;
}
.customSelect{
    box-sizing: border-box;
    padding: 0;
    width: 100%;
    height: 100%;
}
.containerFilter input[type="text"]{
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    padding-left: 13px;
    outline: none;
}
.statsCont{
    width: calc( 20% - 5px );
    height: 100%;
    border-radius: 5px;
    background-color: #F3F3F3;
    box-sizing: border-box;
    padding: 5px;
    display: flex;
}
.statsSquare{
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 5px;
}
.statsSquare p{
    margin: 0;
    font-weight: bold;
}
.homeTableSquare {
    flex: 1; /* Ocupa o espaço restante no container */
    border-radius: 5px;
    overflow-y: auto; /* Scroll vertical */
    overflow-x: auto; /* Scroll horizontal */
    background-color: #F3F3F3;
    display: flex;
    flex-direction: column;
    box-sizing: border-box; /* Para incluir padding/border no cálculo de tamanho */
    position: relative; /* Permite manipulações posicionais se necessário */
    width: 100%; /* Garante que o componente ocupe 100% da largura do container */
    max-height: calc(100vh - 70px - 20px); /* Ajusta a altura máxima, considerando o header e padding */
}
.homeTableSquare::-webkit-scrollbar{
    width: 12px;
    height: 12px;
}
.homeTableSquare::-webkit-scrollbar-thumb{
    background-color: #BEBEBE;
    border-radius: 5px;
    border: 2px solid #F3F3F3;
}
.colabPend, .colabOk{
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.colabPend{
    background-color: red;
}
.colabOk{
    background-color: green;
}