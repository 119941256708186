.loginContent{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
}
.logoPalmont{
    height: 55px;
    margin: auto auto 0 auto;
}
.containerLogin{
    width: 70%;
    display: flex;
    flex-direction: column;
    margin: auto;
}
.emailLogin{
    height: 40px;
    border-radius: 5px;
    border: none;
    outline: none;
    font-size: 17px;
    padding-left: 15px;
}
.emailLogin{
    margin-bottom: 5px;
}
#forgotPass{
    margin: 5px 0 0 auto;
    cursor: pointer;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 13px;
}
#enterBtn{
    margin: 30px auto 0 auto;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: none;
    outline: none;
    font-size: 17px;
    font-weight: bold;
    background-color: #0FB50B;
    color: white;
    cursor: pointer;
    transition: .3s;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loadingIcon{
    margin: auto;
    animation: rotate .9s linear infinite;
    width: 30px;
    height: 30px;
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


#enterBtn:hover{
    scale: 1.03;
}
#registerBtn{
    margin: 25px auto 0 auto;
    font-size: 17px;
    font-weight: bold;
    border: none;
    outline: none;
    color: white;
    cursor: pointer;
    border-bottom: 3px solid transparent;
    display: flex;
    padding: 0 5px;
}
#registerBtn p{
    margin: auto;
}
#registerBtn:hover{
    border-bottom: 3px solid white;
}
.loginPass{
    width: 100%;
    height: 40px;
    background-color: white;
    margin-top: 5px;
    border-radius: 5px;
    display: flex;
}
.loginPass input{
    width: 100%;
    outline: none;
    border: none;
    background-color: transparent;
    padding-left: 15px;
    font-size: 17px;
}
#btnShowPass{
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    background-color: transparent;
    cursor: pointer;
    margin: 0 10px 0 auto;
    color: rgba(0, 0, 0, 0.6);
}
.my-toast{
    font-size: 16px;
}