.repsHeader{
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
}
.repsFilters{
    width: calc( 80% - 2.5px );
    height: 100%;
    background-color: #DEDEDE;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    flex-direction: column;
}
.repsFilters .filter-row-1{
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    margin-bottom: 2.5px;
    display: flex;
    justify-content: space-between;
}
.repsFilters .filter-row-2{
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    margin-top: 2.5px;
    display: flex;
    justify-content: space-between;
}

.repsFilters .filter-row-1 select, .repsFilters .filter-row-2 select{
    border: none;
    outline: none;
    width: calc( 50% - 2.5px );
    box-sizing: border-box;
    padding-left: 15px;
    border-radius: 5px;
}


.repsStats{
    width: calc( 20% - 2.5px );
    height: 100%;
    background-color: #DEDEDE;
    border-radius: 5px;
    display: flex;
}
.qtdVagasRep{
    padding: 8px 13px;
    background-color: #F0F4F8;
    margin: auto;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: .3s;
}
.qtdVagasRep p{
    font-size: 15px;
    font-weight: bold;
    margin: 0;
}