.container{
    width: 100%;
    height: calc( 100%  - 70px );
    background-color: #242D34;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px;
    gap: 10px;
}
.header{
    width: 100%;
    border-radius: 5px;
    display: flex;
    gap: 10px;
}
.filtersContainer{
    flex: 1;
    background-color: #F3F3F3;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat( 3, 1fr );
    gap: 10px;
}
.filterBox{
    flex: 1;
    height: 38px;
    display: flex;
}
.customSelect{
    box-sizing: border-box;
    padding: 0;
    width: 100%;
    height: 100%;
}
.statsContainer{
    background-color: #F3F3F3;
    border-radius: 5px;
    max-width: 50%;
    display: flex;
    box-sizing: border-box;
    padding: 5px;
    gap: 5px;
}
.statsLeft{
    display: grid;
    grid-template-columns: repeat( 2, 1fr );
    gap: 10px;
    padding: 10px;
}
.confContainer{
    width: 50px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.statesSeparator{
    background-color: black;
    width: 3px;
    height: 70%;
    margin: auto 0;
}
.statsRight{
    display: flex;
}
.circlePercent{
    width: 58px;
    height: 58px;
    border-radius: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto 10px;
}
.lowPercent{
    border: 4px solid red;
}
.mediumPercent{
    border: 4px solid yellow;
}
.highPercent{
    border: 4px solid green;
}

.content{
    width: 100%;
    flex: 1;
    background-color: #F3F3F3;
    border-radius: 5px;
    overflow: auto;
}
.content::-webkit-scrollbar{
    width: 10px;
    height: 8px;
}
.content::-webkit-scrollbar-thumb{
    background-color: #C1C1C1;
    border-radius: 5px;
    border: 2px solid #F3F3F3;
}
.normaColumn{
    text-align: left;
}