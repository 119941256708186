.container{
    width: 550px;
    max-height: 80%;
    border-radius: 5px;
    margin: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.header{
    width: 100%;
    height: 40px;
    background-color: #263B4B;
    flex-shrink: 0;
    display: flex;
}
.closeBox{
    width: 30px;
    height: 30px;
    background-color: red;
    margin: auto 5px auto auto;
    cursor: pointer;
}

.containerBody{
    background-color: white;
    overflow: auto;
    flex: 1;
    width: 100%;
}
.containerBody::-webkit-scrollbar{
    width: 10px;
    height: 10px;
}
.containerBody::-webkit-scrollbar-thumb{
    background-color: #C1C1C1;
    border-radius: 3px;
}
.loadContainer{
    color: black;
}
.formulario{
    width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 10px 0 20px 0;
}
.anexos{
    color: black;
    display: flex;
    flex-direction: column;
}
.anexosTitle{
    font-weight: bold;
    margin: 15px auto auto auto;
}
.anexosList{
    width: 100%;
    box-sizing: border-box;
    margin-top: 10px;
    border-radius: 5px;
    color: black;
    overflow: hidden;
}
.teste{
    color: black;
    background-color: red;
    width: 100%;
    height: 10px;
}