.overlayEditUser{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
}
.editContainer{
    width: 370px;
    background-color: white;
    margin: auto;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
}
.editContainer p{
    margin: 10px auto 15px auto;
    font-weight: bold;
}
.editContainer form{
    width: 100%;
    margin-bottom: 10px;
}
.editContainer form select{
    width: 100%;
    height: 30px;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.6);
    box-sizing: border-box;
    padding-left: 5px;
    margin-bottom: 10px;
}
.editContainer form button{
    width: 100%;
    height: 30px;
    border-radius: 5px;
    outline: none;
    border: none;
    background-color: green;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: .3s;
}
.editContainer form button:hover{
    scale: 1.06;
}