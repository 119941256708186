.bodyContainer{
    width: 100%;
    flex: 1;
    background-color: #DEDEDE;
    border-radius: 5px;
    overflow: auto;
    position: relative;
}
.atrasado{
    color: red;
    font-weight: bold;
}
.bodyContainer .vencendo{
    color: rgb(255, 166, 0);
    font-weight: bold;
}
.loading{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    display: flex;
    z-index: 9999;
}
.loading p{
    margin: auto;
    padding: 5px 10px;
    background-color: white;
    border-radius: 5px;
    font-weight: bold;
}