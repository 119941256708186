.infoContainer{
    width: calc( 100% - 450px );
    margin: 10px 10px 10px 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.colabStats{
    width: 100%;
    margin: 0;
    background-color: #DEDEDE;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}
.repubDiv{
    width: 100%;
    background-color: #DEDEDE;
    margin: 10px 0 0 0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}
#infoTitle{
    margin: 15px auto;
    font-weight: bold;
}
#repubTitle{
    margin: 15px auto;
    font-weight: bold;
}
.field{
    margin: 3px;
    display: flex;
    width: 100%;
}
.field p{
    margin: auto 10px auto 7px;
    font-weight: bold;
    width: 110px;
    text-align: right;
}
.field input{
    flex: 1;
    height: 30px;
    border: none;
    outline: none;
    border-radius: 3px;
    padding: 0 10px;
    margin: 0 6px 0 0;
}
.field select{
    flex: 1;
    height: 30px;
    border: none;
    border-radius: 3px;
    padding: 0 8px;
    margin: 0 6px 0 0;
}
#save_edit{
    margin: 10px;
    border: none;
    height: 30px;
    border-radius: 3px;
    background-color: #0A5C36;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: .3s;
}
#save_edit:hover{
    scale: 1.01;
}
.repInfo{
    display: flex;
    margin: 0 0 10px 0;
}
.repubName{
    flex: 1;
    margin: 0 5px 0 10px;
    border: none;
    border-radius: 5px;
    padding: 10px;
}
.dataIn, .dataOut{
    width: 120px;
    height: 40px;
    padding: 0 5px;
    border: 0;
    border-radius: 5px;
}
.dataIn{
    margin: 0 5px;
}
.dataOut{
    margin: 0 10px 0 5px;
}
#checkout_p{
    margin: auto 10px auto 5px;
    width: auto;
}
.alocado{
    display: flex; 
}
.alocado select{
    width: 20%;
}
#aloj_p{
    margin: auto 5px auto 2px;
    width: auto;
}
#chefe_p{
    margin: auto 5px auto 2px;
    width: auto;
}