.container{
    width: 550px;
    max-height: 85%;
    color: black;
    border-radius: 5px;
    margin: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.header{
    width: 100%;
    height: 40px;
    background-color: #263B4B;
    flex-shrink: 0;
    display: flex;
}
.closeBox{
    width: 30px;
    height: 30px;
    background-color: red;
    margin: auto 7px auto auto;
    cursor: pointer;
}
.body{
    width: 100%;
    max-height: calc( 100% - 40px );
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow: auto;
}
.body form{
    width: 90%;
    margin: 10px auto 20px auto;
}