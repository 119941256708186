.overlayNewDist{
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9999;
    width: 100%;
    height: 100dvh;
}
.squareNewDist{
    width: 550px;
    max-height: 90%;
    background-color: white;
    border-radius: 5px;
    margin: auto;
    overflow: auto;
}
.squareNewDist::-webkit-scrollbar {
    width: 13px;
    height: 13px;
    background-color: transparent;
}  
.squareNewDist::-webkit-scrollbar-thumb {
    background-color: rgb(158, 158, 158);
    border-radius: 7px;
    border: 3px solid white;
}
.squareNewDist::-webkit-scrollbar-corner {
    background-color: white;
    border-radius: 100%;
}
.formField{
    width: 95%;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
}
.formField select{
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding-left: 10px;
    outline: none;
}
.formField input{
    height: 40px;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.3);
    margin: 0;
    padding: 0;
    padding-left: 10px;
}
.formField p{
    margin: 5px auto;
    font-weight: bold;
}
.headerSquare{
    width: 95%;
    display: flex;
    margin: 0 auto;
}
.closeIcon{
    margin: 10px 0 2px auto;
    color: red;
    cursor: pointer;
    transition: .3s;
}
.closeIcon:hover{
    scale: 1.16;
}
#formulario{
    margin: 0 0 15px 0;
}
.show{
    display: flex;
}
.hide{
    display: none;
}