.container{
    width: 100%;
    flex: 1;
    background-color: #242D34;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px;
    gap: 10px;
}
.header{
    width: 100%;
    border-radius: 5px;
    display: flex;
    gap: 10px;
}
.containerFilters{
    width: calc( 75% - 5px );
    background-color: #F3F3F3;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat( 3, 1fr );
    gap: 10px;
}
.filterBox{
    flex: 1;
    height: 38px;
    display: flex;
}
.filterBox input[type="date"]{
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 0;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 0 10px;
}
.customSelect{
    box-sizing: border-box;
    padding: 0;
    width: 100%;
    height: 100%;
}
.containerStats{
    width: calc( 25% - 5px );
    background-color: #F3F3F3;
    border-radius: 5px;
    display: flex;
    box-sizing: border-box;
    padding: 10px;
}
.boxStats{
    flex: 1;
    margin: auto;
    display: flex;
    text-align: center;
    gap: 5px;
}
.stats1{
    width: 50%;
}
.stats2{
    width: 50%;
}
.boxStats p{
    margin: 0;
    font-weight: bold;
}
.body{
    width: 100%;
    flex: 1;
    background-color: #F3F3F3;
    border-radius: 5px;
    overflow: auto;
}
.editIconBox{
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.editIcon{
    width: 100%;
    height: 100%;
    transition: .3s;
}
.editIcon:hover{
    scale: 1.15;
}
.editedLine{
    width: 20px;
    height: 20px;
    display: flex;
}
.viewIcon{
    width: 90%;
    height: 90%;
    margin: auto;
    transition: .3s;
}
.viewIcon:hover{
    scale: 1.15;
}