.overlay_menu_show{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
}
.overlay_menu_hide{
    display: none;
}
.menuSidebar{
    width: 350px;
    height: 100%;
    margin: 0 0 0 auto;
    background-color: #263B4B;
    display: flex;
    flex-direction: column;
}
.btnMenu{
    display: flex;
    flex-direction: row;
    background-color: transparent;
    width: 85%;
    margin: 5px auto;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: .3s;
}
.btnMenu:hover{
    scale: 1.04;
    background-color: white;
    color: #263B4B;
}
.iconBtn{
    width: 20px;
    height: 20px;
    margin: auto 7px auto 20px;
}
.btnMenu p{
    font-size: 18px;
    margin: 10px auto 10px 7px;
    font-weight: bold;
}
.footerMenu{
    width: 100%;
    margin: auto 0 30px 0;
}
.firstBtn{
    margin-top: 30px;
}
.logOut{
    margin: 0 auto;
}
.logOut:hover{
    background-color: red;
    color: white;
}