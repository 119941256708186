.OverlaySideBarPag{
    width: 100%;
    height: 100dvh;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
}
.show{
    display: flex;
}
.hide{
    display: none;
}
.SideBarPag{
    width: 350px;
    height: calc( 100% - 20px );
    margin: 0 0 0 auto;
    background-color: #263B4B;
    padding-top: 20px;
}
.btn-side-bar{
    width: 85%;
    height: 40px;
    background-color: transparent;
    margin: 10px auto;
    border-radius: 5px;
    cursor: pointer;
    transition: .3s;
    display: flex;
    color: white;
}
.btn-side-bar p{
    font-size: 18px;
    margin: auto auto auto 0;
    font-weight: bold;
}
.btn-side-bar:hover{
    background-color: white;
    scale: 1.03;
    color: #263B4B;
}
.pag-sidebar-icon{
    margin: auto 5px auto 15px;
    width: 30px;
}
