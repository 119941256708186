.admissaoSquare{
    flex: 1;
    background-color: #DEDEDE;
    border-radius: 8px;
    overflow: auto;
    position: relative;
}
.admissaoSquare::-webkit-scrollbar{
    width: 12px;
    height: 12px;
}
.admissaoSquare::-webkit-scrollbar-thumb{
    background-color: #BEBEBE;
    border-radius: 5px;
    border: 2px solid #F3F3F3;
}
.admissaoSquare::-webkit-scrollbar-track{
    background-color: #F3F3F3;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.admissaoSquare::-webkit-scrollbar-corner{
    border-radius: 5px;
    background-color: #F3F3F3;
}
.loadingTable{
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loadingTable svg{
    margin: 0;
    color: white;
    width: 33px;
    height: 33px;
    animation: spinLoad 1s linear infinite;
}
@keyframes spinLoad {
    0%{
        rotate: 0deg;
    }
    50%{
        rotate: 180deg;
    }
    100%{
        rotate: 360deg;
    }
}
table tbody .admisAtras{
    color: rgb(255, 123, 0);
    font-weight: bold;
}