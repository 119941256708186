.colabInfo{
    width: 450px;
    margin: 10px;
    background-color: #DEDEDE;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    overflow: auto;
}
.colabInfo::-webkit-scrollbar {
    width: 13px;
    height: 13px;
    background-color: transparent;
}  
.colabInfo::-webkit-scrollbar-thumb {
    background-color: rgb(158, 158, 158);
    border-radius: 7px;
    border: 3px solid #DEDEDE;
}
.colabInfo::-webkit-scrollbar-corner {
    background-color: white;
    border-radius: 100%;
}
.colabInfo p{
    margin: 0;
}
.child{
    margin: 3px;
    display: flex;
}
.child p{
    font-weight: bold;
    margin: auto 7px auto 0;
    width: 150px;
    text-align: right;
}
.child input{
    width: calc( 100% - 150px );
    margin: 0 0 0 auto;
    height: 30px;
    padding: 0 10px;
    border-radius: 3px;
    border: none;
    outline: none;
    transition: .3s;
}
.child input:focus{
    box-shadow: 0 0 0 3px #00a6ff4b;
}
#dadosTitle{
    margin: 15px auto;
    font-weight: bold;
}