.overlay{
    width: 100%;
    height: 100dvh;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    z-index: 9999;
    transition: .3s;
}
.hide{
    display: none;
}
.show{
    display: flex;
}
.tabelaRep{
    width: 550px;
    max-height: 80%;
    background-color: white;
    margin: auto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.tabelaRep::-webkit-scrollbar {
    width: 13px;
    height: 13px;
    background-color: transparent;
}  
.tabelaRep::-webkit-scrollbar-thumb {
    background-color: rgb(158, 158, 158);
    border-radius: 7px;
    border: 3px solid white;
}
.tabelaRep::-webkit-scrollbar-corner {
    background-color: white;
    border-radius: 100%;
}
.FormField{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 15px auto 0 auto;
}
.FormField p{
    margin: 0 auto 5px auto;
    font-weight: bold;
}
.FormField input{
    height: 40px;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding-left: 15px;
}
.FormField span{
    color: red;
}
#SaveRepBtn{
    width: 90%;
    padding: 10px 0;
    margin: 30px auto;
    background-color: green;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    transition: .3s;
    cursor: pointer;
}
#SaveRepBtn:hover{
    scale: 1.03;
}
#headerForm{
    width: 100%;
    margin: 10px 0 0 0;
    display: flex;
}
#closeIcon{
    width: 35px;
    height: 35px;
    margin: 0 5px 0 auto;
    color: red;
    cursor: pointer;
    transition: .3s;
}
#closeIcon:hover{
    scale: 1.15;
}
.obrasSelect{
    height: 40px;
    border-radius: 5px;
    padding-left: 15px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    outline: none;
}