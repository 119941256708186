.folgasContainer{
    width: 100%;
    margin: 10px 0 0 0;
    border-radius: 5px;
    background-color: #DEDEDE;
    display: flex;
    flex-direction: column;
}
#titleFolgas{
    margin: 15px auto;
    font-weight: bold;
}
.squareFolgas{
    width: calc( 100% - 20px );
    margin: 0 auto 25px auto;
    border-radius: 5px;
    overflow: hidden;
}
.folgasContainer table{
    width: 100%;
    margin: 0 auto;
}