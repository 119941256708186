.container{
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    margin: auto;
}
.txtConfirm{
    color: black;
    margin: 0;
    font-weight: bold;
    font-size: 13px;
}
.boxBtns{
    width: 100%;
    height: 30px;
    margin: 0;
    margin-top: 10px;
    display: flex;
    gap: 5px;
    box-sizing: border-box;
}
.boxBtns button{
    width: calc( 50% - 2.5px );
    border: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 5px;
    color: white;
    transition: .3s;
}
.boxBtns button:hover{
    scale: 1.03;
}
.btnConfirm{
    background-color: darkgreen;
}
.btnCancel{
    background-color: darkred;
}