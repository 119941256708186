.nav_bar{
    width: 100%;
    height: 70px;
    background-color: #263B4B;
    display: flex;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}
#logo_palmont{
    height: 60%;
    margin: auto 0 auto 10px;
}
#newRepBtn{
    margin: auto 15px auto auto;
    padding: 10px 8px;
    border: none;
    outline: none;
    border-radius: 5px;
    font-weight: bold;
    color: white;
    background-color: transparent;
    cursor: pointer;
    transition: .3s;
}
#newRepBtn:hover{
    background-color: white;
    color: #263B4B;
}