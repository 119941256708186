.content_body{
    height: calc( 100% - 70px );
    background-color: #242D34;
    margin: 0;
    display: flex;
    flex-direction: column;
}
.content{
    height: 100% ;
    background-color: blue;
}