.container{
    width: 100%;
    height: 35px;
    display: flex;
    box-sizing: border-box;
    padding: 0 10px;
    align-items: center;
}
.textContainer{
    width: 150px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    margin-right: 5px;
}
.textContainer p{
    margin: 0;
}
.container input[type="text"], .container select, .container input[type="date"], .container input[type="number"]{
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 0 0 0 10px;
}