.container{
    max-width: 90%;
    max-height: 90%;
    margin: auto;
    background-color: #fff;
    border-radius: 5px;
    color: black;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px;
}
.container h3{
    margin: 10px auto 20px auto;
    text-align: center;
}
.tableContainer{
    width: 650px;
    flex: 1;
    margin: 10px auto 0 auto;
    border-radius: 5px;
    overflow: auto;
}
.permissionDenied{
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: red;
}