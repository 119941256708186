.summaryBox{
    margin: auto 10px;
    font-size: 15px;
    font-weight: bold;
    background-color: #f0f4f8;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: .3s;
}
.summaryBox:hover{
    scale: 1.06;
}
.summaryBox:active{
    background-color: #263B4B;
    scale: 1;
    color: white;
}