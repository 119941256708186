.container{
    width: 550px;
    border-radius: 5px;
    margin: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.header{
    width: 100%;
    height: 40px;
    background-color: #263B4B;
    display: flex;
}
.closeBox{
    width: 30px;
    height: 30px;
    margin: auto 5px auto auto;
}
.closeIcon{
    width: 100%;
    height: 100%;
    color: red;
    cursor: pointer;
    transition: .3s;
}
.closeIcon:hover{
    scale: 1.2;
}
.body{
    flex: 1;
    background-color: white;
}
.formulario{
    width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 10px 0 20px 0;
}