.formContainer{
    width: 550px;
    max-height: 85%;
    background-color: white;
    border-radius: 5px;
    margin: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
}
.closeContainer{
    width: 35px;
    height: 35px;
    margin: 15px 15px 5px auto;
    display: flex;
    cursor: pointer;
}
.closeContainer svg{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    color: red;
    transition: .3s;
}
.saveChanges, .waitChanges{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    align-items: center;
    justify-content: center;
    color: white;
}
.saveChanges{
    display: flex;
}
.waitChanges{
    display: none;
}
.saveChanges svg{
    animation: rotatingSvg linear 1s infinite;
}
@keyframes rotatingSvg{
    0%{
        rotate: 0deg;
    }
    50%{
        rotate: 180deg;
    }
    100%{
        rotate: 360deg;
    }
}