.container{
    height: 100dvh;
    display: flex;
    flex-direction: column;
}
.contentReps{
    width: 100%;
    flex: 1;
    background-color: #242D34;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px;
}
.tabela{
    flex: 1;
    margin: 10px;
    background-color: white;
    border-radius: 5px;
    transition: .3s;
}