.container{
    margin: auto;
    max-width: 95%;
    background-color: white;
    border-radius: 5px;
    color: black;
    box-sizing: border-box;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
}
.txtConfirm{
    margin: 10px 0;
    text-align: center;
    font-weight: bold;
    font-size: 13px;
}
.motivoArea{
    width: 420px;
    margin-bottom: 5px;
}
.motivoTxt{
    max-width: 100%;
    min-width: 100%;
    border-radius: 5px;
    box-sizing: border-box;
    outline: none;
    padding: 5px;
}
.btnsBox{
    width: 100%;
    height: 30px;
    margin: 0;
    display: flex;
    gap: 5px;
    box-sizing: border-box;
}
.btnsBox button{
    width: calc( 50% - 2.5px );
    border: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 5px;
    color: white;
    transition: .3s;
}
.btnsBox button:hover{
    scale: 1.03;
}
.btnConfirm{
    background-color: darkgreen;
}
.btnCancel{
    background-color: darkred;
}