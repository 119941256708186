.checklistField{
    width: 100%;
    display: flex;
    padding: 13px 0;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
}
.checklistField p{
    width: 100%;
    text-align: center;
    margin: 15px 0 25px 0;
    font-weight: bold;
}