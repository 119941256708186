.filtersContainer{
    width: calc( 85% - 2.5px );
    background-color: #DEDEDE;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    border-radius: 5px;
}
.filterChild{
    flex: 1;
    min-width: 350px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}
.filterChild select{
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
    border-radius: 5px;
}
.filterChild input[type="date"]{
    width: calc( 50% - 2.5px );
    height: 40px;
    border: none;
    outline: none;
    border-radius: 5px;
    padding-left: 10px;
    box-sizing: border-box;
}