.container{
    width: 550px;
    max-height: 80%;
    border-radius: 5px;
    margin: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.containerHeader{
    width: 100%;
    height: 40px;
    background-color: #263B4B;
    flex-shrink: 0;
    display: flex;
}
.closeBox{
    width: 30px;
    height: 30px;
    margin: auto 5px auto auto;
}
.closeIcon{
    width: 100%;
    height: 100%;
    color: red;
    cursor: pointer;
    transition: .3s;
}
.closeIcon:hover{
    scale: 1.2;
}
.containerBody{
    background-color: white;
    overflow: auto;
    flex: 1;
    width: 100%;
}
.containerBody::-webkit-scrollbar{
    width: 10px;
    height: 10px;
}
.containerBody::-webkit-scrollbar-thumb{
    background-color: #C1C1C1;
    border-radius: 3px;
}
.formulario{
    width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 10px 0 20px 0;
}
.normaItem{
    box-sizing: border-box;
    padding: 20px 0 0 0;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.normaItem p{
    margin: 0;
}
.normaItemTitle{
    color: black;
    text-align: center;
    font-weight: bold;
}
.addRmvItens{
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    margin-top: 20px;
    display: flex;
    gap: 10px;
}
.addRmvItens button{
    flex: 1;
    border: none;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    font-weight: bold;
}
.addItemBtn{
    background-color: green;
}
.rmvItemBtn{
    background-color: red;
}
.loadingContainer{
    width: 100%;
    background-color: white;
    display: flex;
}
.loadingBox{
    height: 40px;
    margin: 30px auto;
    display: flex;
    box-sizing: border-box;
    padding: 10px;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.loadingTxt{
    margin: 0;
    color: black;
}
.spinnerContainer{
    width: 20px;
    height: 20px;
}
.spinnerIcon{
    width: 100%;
    height: 100%;
    animation: spinner 1s linear infinite;
    color: black;
}

@keyframes spinner{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}