.container{
    width: 950px;
    max-width: 90%;
    max-height: 80%;
    border-radius: 5px;
    margin: auto;
    display: flex;
    flex-direction: column;
}
.header{
    width: 100%;
    height: 40px;
    background-color: #263B4B;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    flex-shrink: 0;
    display: flex;
    position: relative;
}
.headerName{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    font-size: 14px;
}
.pdfBox{
    width: 30px;
    height: 30px;
    background-color: white;
    margin: auto 0 auto auto;
}
.closeBox{
    width: 30px;
    height: 30px;
    margin: auto 10px auto 5px;
}
.closeBox svg{
    width: 100%;
    height: 100%;
    color: red;
    cursor: pointer;
    transition: .3s;
}
.closeBox svg:hover{
    scale: 1.2;
}
.body{
    width: 100%;
    max-height: calc( 100% - 40px );
    overflow: auto;
    display: flex;
    flex-direction: column;
    border-radius:  0 0 5px 5px;
}
.loadingContent{
    width: 100%;
    height: 150px;
    display: flex;
    background-color: white;
}
.loadingBox{
    margin: auto;
}
.loadIcon{
    color: #263B4B;
    animation: spin 1s linear infinite;
}
@keyframes spin {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
.quartosContent{
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
}
.titleQuartos{
    width: 95%;
    height: 30px;
    margin: 10px auto;
    display: flex;
}
.titleQuartos p{
    text-decoration: underline;
    font-weight: bold;
    margin: auto;
    color: black;
    font-size: 17px;
}

.quartoBox{
    width: 95%;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
}
.quartoInfo{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    background-color: #F3F3F3;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    margin: auto;
    box-sizing: border-box;
    padding: 5px 8px;
    border-radius: 2px;
}
.quartoInfo p{
    margin: 0;
    color: black;
    font-size: 14px;
}

.quartoContainer{
    width: 100%;
    margin-top: 10px;
    border-radius: 5px;
    overflow: hidden;
}
.newRoom{
    width: 95%;
    height: 30px;
    display: flex;
    margin: 15px auto 25px auto;
    box-sizing: border-box;
    transition: .3s;
}
.newRoom:hover{
    scale: 1.01;
}
.newRoom button{
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 3px;
    background-color: green;
    color: white;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    cursor: pointer;
}
.formQuartos{
    width: 100%;
    background-color: white;
    color: black;
}
.formQuartos form{
    width: 95%;
    margin: 20px auto;
}